import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Bonjour tout le monde, Je suis <span className="purple">Mehdi Chafai</span>{" "}
            de <span className="purple"> Lille, France.</span>
            <br />Je suis un étudiant à l'école d'ingénieur Junia ISEN Lille en cycle informatique et réseaux.
            <br />
            <br />
            À part coder, voici ce que j'aime également!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Jouer aux jeux vidéo
            </li>
            <li className="about-activity">
              <ImPointRight /> Apprendre de nouvelles choses sur la cybersécurité
            </li>
            <li className="about-activity">
              <ImPointRight /> Voyager
            </li>
          </ul>

          <p style={{ color: "#00d9ff" }}>
            "Le travail est l'aliment des âmes nobles."{" "}
          </p>
          <footer className="blockquote-footer">Sénèque</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
