import "./Contact.css";
import emailjs from "emailjs-com";
import Particle from "../Particle";
import { useRef } from "react";

export const Contact = () => {
    const form = useRef();

    let textInput = document.getElementsByClassName("form-control");

    const sendEmail = (e) => {
        e.preventDefault();

        if (textInput[0].value === "" || textInput[1].value === "" || textInput[2].value === "") {
            alert("Veuillez remplir tous les champs");
            window.location.reload(true);
        }
        else {
            emailjs.sendForm('service_mbx9sdm', 'template_acjuauv', form.current, 'FdVthc9PYPYR6R2s3')
                .then((result) => {
                    console.log(result.text);
                });

            alert("Votre message a bien été envoyé");
            window.location.reload(true);
        }
    };



    return (
        <div className="contact">
            <div className="container">
                <div className="row">
                    <div className="col align-self-center">

                        {/* <!-- contact form --> */}
                        <form ref={form} onSubmit={sendEmail}>
                            {/* <!-- name --> */}
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="from_name"
                                    className="form-control"
                                    id="name"
                                    placeholder="Nom et Prénom"
                                />
                            </div>

                            {/* <!-- email --> */}
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="from_email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                />
                            </div>

                            <div className="form-group">
                                <textarea
                                    name="message"
                                    className="form-control"
                                    id="email_body"
                                    rows="5"
                                    placeholder="Message"
                                ></textarea>
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Envoyer
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Particle />
        </div>
    );
};

export default Contact;