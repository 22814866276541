import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Memoji.png";
import Tilt from "react-parallax-tilt";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import styled from "styled-components";

const StyledSection = styled.section`
  color: #ffffff; /* Blanc pour le texte principal */

  .purple {
    color: #00d9ff; /* Bleu néon pour les accents */
  }

  .home-about-description {
    font-family: "Roboto Mono", monospace;
    color: #ffffff; /* Blanc pur pour les paragraphes principaux */
  }

  h1 {
    font-family: "Exo 2", sans-serif;
    color: #00d9ff; /* Bleu néon pour les titres */
  }

  .home-about-social h1 {
    font-family: "Exo 2", sans-serif;
    color: #00d9ff; /* Bleu néon pour le titre des sections sociales */
  }

  .home-about-social p {
    color: #cccccc; /* Gris clair pour les sous-titres et les descriptions des sections sociales */
    font-family: "Roboto Mono", monospace;
  }

  i {
    font-family: "Roboto Mono", monospace;
    color: #00d9ff
  }
`;

function Home2() {
  return (
    <StyledSection>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={8} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>
                Laissez moi me présenter
              </h1>
              <p className="home-about-body">
                Je suis tombé amoureux de la programmation et j'ai au moins appris quelque chose, je pense… 🤷‍♂️
                <br />
                <br />
                J'utilise souvent les langages de programmation suivants :{" "}
                <i>
                  C++, Javascript et Python.
                </i>
                <br />
                <br />
                Mes centres d'intérêts sont de{" "}
                <i>
                  réaliser des Challenges Root-Me et TryHackMe et
                  participer à{" "}
                  des bug bounty.
                </i>
                <br />
                <br />
                Dans la mesure du possible, j'applique également ma passion pour le développement web avec Node.js et{" "}
                <i>
                  Des Libraries et Frameworks Modernes
                </i>
                &nbsp; comme{" "}
                <i>
                  React.js et Qt pour le C++.
                </i>
              </p>
            </Col>
            <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>RETROUVEZ-MOI SUR</h1>
              <p>
                N'hésitez pas à regarder mon profil sur les réseaux sociaux.
              </p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="https://www.linkedin.com/in/MehdiChafai/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.instagram.com/mehdidc/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </StyledSection>
  );
}

export default Home2;