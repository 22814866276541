import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    DiPerl,
    DiReact,
    DiDocker,
  } from "react-icons/di";

function Learnstack() {
    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons">
            <DiPerl />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiReact />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiDocker />
        </Col>
        </Row>
    );
}

export default Learnstack;