import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      params={{
        particles: {
          number: {
            value: 120, // Moins de particules pour un design plus épuré
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#00ccff", // Couleur cyan pour un look high-tech
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
          },
          line_linked: {
            enable: false,
            opacity: 0.02,
          },
          move: {
            direction: "random", // Direction aléatoire pour imiter des étoiles filantes
            speed: 0.1, // Légèrement plus rapide
          },
          size: {
            value: 2, // Particules légèrement plus grandes
            random: true,
          },
          opacity: {
            value: 0.7, // Plus de visibilité
            random: true,
            anim: {
              enable: true,
              speed: 0.2,
              opacity_min: 0.1,
            },
          },
        },
        interactivity: {
          events: {
            onclick: {
              enable: true,
              mode: "repulse", // Répulsion au clic pour un effet de dispersion
            },
            onhover: {
              enable: true,
              mode: "grab", // Les particules se rapprochent au survol
            },
          },
          modes: {
            push: {
              particles_nb: 3,
            },
            repulse: {
              distance: 100,
              duration: 0.4,
            },
            grab: {
              distance: 140,
              line_linked: {
                opacity: 0.2,
              },
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
}

export default Particle;