import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Bank from "../../Assets/Projects/bank.png";
import BattleCheap from "../../Assets/Projects/warship.png";
import CiriousGame from "../../Assets/Projects/biohazard.png";
import FourthProject from "../../Assets/Memoji.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes derniers <strong className="purple">Projets </strong>
        </h1>
        <p style={{ color: "white" }}>
          Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={CiriousGame}
              isBlog={false}
              title="CiriousGame"
              description="Projet consistant à créer un jeu avec une thématique actuelle, codé en JS avec le framework Phaser. Le jeu consiste à éradiquer un virus en l'éliminant avec des réformes pouvant être mises en place. Le jeu est disponible sur mon GitHub."
              ghLink="https://github.com/DjallalChafai/CiriousGame"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Bank}
              isBlog={false}
              title="Gestion de banque"
              description="Projet consistant à créer une simulation de gestion bancaire. Le but était d'implémenter 3 banques pouvant être contactées par des sockets. Le projet a été codé en C++ avec Qt pour l'interface graphique, et utilise une base de données SQL et des sockets pour communiquer avec les banques. Les banques peuvent émettre des crédits, des dépôts, des prêts, des transferts, etc. Le projet est disponible sur mon GitHub."
              ghLink="https://github.com/DjallalChafai/gestion-banque"
            />
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={BattleCheap}
              isBlog={false}
              title="BattleCheap"
              description="Petit jeu créé en JS utilisant Socket.io pour la communication entre joueurs (uniquement multijoueur). Le but est de détruire les navires ennemis en tirant sur eux. Le jeu est disponible sur mon GitHub."
              ghLink="https://github.com/DjallalChafai/BattleCheap"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={FourthProject}  // Ajoutez un nouveau projet ici
              isBlog={false}
              title="Quatrième Projet"
              description="Description du quatrième projet ici. Le projet est disponible sur mon GitHub."
              ghLink="https://github.com/DjallalChafai/FourthProject"
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;