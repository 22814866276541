import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import styled from "styled-components";
import homeLogo from "../../Assets/home-main.svg"; // Remplace cette image par une illustration plus personnalisée si possible

const StyledSection = styled.section`
  background-size: cover;
  color: #ffffff; /* Blanc pur pour le texte */
  
  .heading {
    color: #00d9ff; /* Bleu néon pour les titres */
    font-family: "Exo 2", sans-serif; /* Police futuriste pour les titres */
  }
  
  .main-name {
    font-family: "Exo 2", sans-serif;
    color: #00d9ff; /* Mettre le nom en bleu néon pour le faire ressortir */
  }
  
  .subtext {
    color: #cccccc; /* Gris clair pour les sous-titres ou les éléments moins importants */
    font-family: "Roboto Mono", monospace; /* Police monospace pour un effet technique */
  }

  .floating-animation {
    animation: float 4s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;

function Home() {
  return (
    <StyledSection>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hello There!{" "}
                <span className="wave" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                Je suis <strong className="main-name">Mehdi Chafai</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid floating-animation"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </StyledSection>
  );
}

export default Home;